import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import WhiteLogo from "../images/francoisLogoENwhite2.svg"
import styled, { css } from "styled-components"
import InfoBlock from "../components/InformationBlock"
import { media } from "../styles/media"

const StyledDiv = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  width: 100%;
  height: auto;
  .mainVisual {
    width: 100%;
    height: 60vh;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .topLogo {
    position: absolute;
    width: 350px;
    height: 400px;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.primary.red};
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    padding: 80px 30px;
    right: 5%;
    bottom: 10vh;
    img {
      width: 80%;
    }
    p {
      color: white;
      padding-bottom: 1.32em;
    }
  }
  ${() =>
    media.tablet(css`
      height: auto;
      .mainVisual {
        height: 35vh;
      }
      .topLogo {
        top: 10vh;
        width: 35%;
        height: 200px;
        padding: 20px;
        img {
          width: 60%;
        }
        p {
          font-size: 0.9em;
        }
      }
    `)}
  ${() =>
    media.sp(css`
      height: auto;
      .mainVisual {
        height: 45vh;
      }
      .topLogo {
        top: 10vh;
        width: 60%;
        height: 20vh;
      }
    `)}
`

const MainVisualTop = () => {
  const data = useStaticQuery(query)
  return (
    <StyledDiv>
      <div className="mainVisual">
        <Image fluid={data.file.childImageSharp.fluid} />
      </div>
      <div className="topLogo">
        <p>
          これまでも、これからも。
          <br />
          健康とおいしさのために。
        </p>
        <img src={WhiteLogo} alt="francoisLogo" />
      </div>
      <InfoBlock />
    </StyledDiv>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "nightFrancoisImageHero.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default MainVisualTop
