import React from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import Image from "gatsby-image"
import { media } from "../styles/media"

const StyledDiv = styled.div`
  background: ${({ theme }) => theme.colors.primary.gray};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  position: relative;
  padding: 50px 5% 50px 3%;
  .ourBiz {
    width: 22%;
    background: ${({ theme }) => theme.colors.primary.red};
    color: white;
    display: flex;
    flex-flow: column nowrap;
    padding: 30px;
    height: 250px;
    p {
      margin-top: 0.62em;
      font-size: 0.9em;
    }
  }
  .ourBizs {
    width: 74%;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    .ourBizParts {
      width: 22%;
      margin-bottom: 20px;
      margin-left: 3%;
      box-shadow: 3px 3px 5px #acacac;
      transition: 0.5s;
      position: relative;
      &:hover {
        box-shadow: none;
      }
      .oBP1 {
        width: 100%;
        height: 170px;
        .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .oBP2 {
        background: white;
        height: 80px;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        h4 {
          font-size: 0.8em;
          color: #acacac;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;
          &:after {
            display: block;
            content: "";
            width: 50px;
            height: 2px;
            border-radius: 3px;
            background: #dadada;
            margin: 5px 0;
          }
        }
      }
      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
  ${() =>
    media.tablet(css`
      .ourBiz {
        padding: 20px;
      }
      .ourBizs {
        justify-content: space-between;
        .ourBizParts {
          width: 47%;
        }
      }
    `)}
  ${() =>
    media.sp(css`
      padding: 30px 5% 50px;
      .ourBiz {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        box-shadow: 3px 3px 5px #acacac;
      }
      .ourBizs {
        width: 100%;
        .ourBizParts {
          margin-left: 0;
          box-shadow: 4px 4px 0 #dadada;
          .oBP1 {
            height: 100px;
          }
          .oBP2 {
            height: 60px;
          }
        }
      }
    `)}
`

const BizDep = () => {
  const data = useStaticQuery(query)
  return (
    <StyledDiv>
      <div className="ourBiz" id="anchorBiz">
        <h2>OUR BUSINESS</h2>
        <p>事業紹介</p>
      </div>
      <div className="ourBizs">
        {data.zoo.topSections.map((panda, key) => (
          <div className="ourBizParts" key={key}>
            <div className="oBP1">
              <Image fluid={panda.bizPhoto.childImageSharp.fluid} />
            </div>
            <div className="oBP2">
              <h4>{panda.enName}</h4>
              <h5>{panda.jpName}</h5>
            </div>
            <Link to={panda.linkTo}></Link>
          </div>
        ))}
      </div>
    </StyledDiv>
  )
}

export const query = graphql`
  {
    zoo: strapiIndexParts {
      topSections {
        id
        enName
        jpName
        linkTo
        bizPhotoAlt
        bizPhoto {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default BizDep
