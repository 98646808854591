export async function fetcher(url) {
  try {
    const response = await fetch(url, {
      headers: {
        "content-type": "application/json",
      },
    })
    const data = await response.json()
    return data
  } catch (err) {
    return err
  }
}
