import React from "react"
import styled, { css } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { media } from "../styles/media"
import { BsExclamationCircle } from "react-icons/bs"
import { IconContext } from "react-icons"

// const Virus = () => (
//   <IconContext.Provider value={{ color: "#555" }}>
//     <BsExclamationCircle />
//   </IconContext.Provider>
// )

const StyledDiv = styled.div`
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 70%;
  h2 {
    margin-left: 5%;
  }
  span#sepGreen {
    display: block;
    height: 60px;
    width: 2px;
    border-radius: 5px;
    background: ${({ theme }) => theme.colors.primary.green};
    margin: 0 20px;
  }
  .infos {
    padding: 20px 0;
    .info {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin: 8px 0;
      span {
        font-size: 0.8em;
        &.slushBet {
          margin: 0px 13px;
        }
      }
      a {
        overflow: hidden;
        &:after {
          transition: 0.8s;
          display: block;
          content: "";
          height: 1px;
          width: 100%;
          background: ${({ theme }) => theme.colors.primary.red};
          transform: translateX(-100%);
          opacity: 0;
        }
      }
      a:hover:after {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }
  ${() =>
    media.tablet(css`
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
      margin-left: 3%;
      h2 {
        display: none;
      }
      span#sepGreen {
        margin-left: 0;
        width: 10%;
        height: 2px;
        display: none;
      }
      .infos .info {
        font-size: 0.9em;
        a {
          padding: 5px 0;
        }
      }
    `)}
  ${() =>
    media.sp(css`
      width: 100%;
      margin-left: 0;
      .infos {
        margin: auto;
      }
    `)}
`

// const VirDiv = styled.div`
//   border: 1px solid ${({ theme }) => theme.colors.primary.green};
//   color: #555;
//   width: 55%;
//   margin: 24px 5% 0px;
//   display: flex;
//   flex-flow: column nowrap;
//   align-items: center;
//   padding: 12px;
//   p {
//     display: flex;
//     flex-flow: row nowrap;
//     align-items: center;
//     span {
//       font-size: 0.8em;
//       margin-left: 0.62em;
//     }
//   }
//   a {
//     color: ${({ theme }) => theme.colors.primary.green};
//     margin-top: 8px;
//     text-decoration: underline;
//   }
//   ${() =>
//     media.tablet(css`
//       width: 90%;
//       }
//     `)}
// `

const InformationBlock = () => {
  const data = useStaticQuery(query)
  return (
    <>
      {/* <VirDiv>
        <p>
          <Virus />
          <span>新型コロナウイルス感染対応について</span>
        </p>
        <Link to="/info-coronavirus">
          最新の新型コロナウイルス感染対応はこちら
        </Link>
      </VirDiv> */}
      <StyledDiv>
        <h2>お知らせ</h2>
        <span id="sepGreen"></span>
        <div className="infos">
          {data.zoo.nodes.map((panda, key) => (
            <div className="info" key={key}>
              <span>{panda.pubDate}</span>
              <span className="slushBet">/</span>
              <Link to={"/information/" + panda.slug + "/"}>
                {panda.infoTitle}
              </Link>
            </div>
          ))}
        </div>
      </StyledDiv>
    </>
  )
}

export const query = graphql`
  {
    zoo: allStrapiFrancoisInformation(
      sort: { order: DESC, fields: pubDate }
      filter: { infoVisible: { eq: true } }
      limit: 5
    ) {
      nodes {
        infoTitle
        pubDate
        slug
      }
    }
  }
`

export default InformationBlock
