import React, { useEffect } from "react"
import { useQueryClient } from "react-query"

import { queryKey } from "../libs/react-query/constants"
import { fetcher } from "../libs/fetcher/fetcher"
import BizDep from "../components/BizDep"
import Layout from "../components/Layout"
import MainVisualTop from "../components/MainVisualTop"
import ProfileBlock from "../components/ProfileBlock"
import SEO from "../components/SEO"

const API_URL =
  process.env.STRAPI_API_URL + "/corona-infos?_sort=published_at:DESC"

const getCoronaInfoBlogs = async () => await fetcher(API_URL)

const IndexPage = () => {
  // Prefetch corona info data
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.prefetchQuery(queryKey.coronaInfo, getCoronaInfoBlogs)
  }, [queryClient])

  return (
    <Layout>
      <SEO>
        <title>企業情報 | フランソア</title>
        <meta
          name="description"
          content="安全で安心、そしておいしく健康に。天然素材のパンをお届けする株式会社フランソアのホームページ"
        />
      </SEO>
      <MainVisualTop />
      <BizDep />
      <ProfileBlock />
    </Layout>
  )
}

export default IndexPage
