import React from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { media } from "../styles/media"

//icons
import { IconContext } from "react-icons"
import {
  RiBuilding2Line,
  RiRoadMapLine,
  RiPlantLine,
  RiServiceLine,
  RiUserSearchLine,
  RiChatSmile3Line,
} from "react-icons/ri"

const Office = () => (
  <IconContext.Provider value={{ color: "#E50012", size: "25px" }}>
    <RiBuilding2Line />
  </IconContext.Provider>
)
const Mapicon = () => (
  <IconContext.Provider value={{ color: "#E50012", size: "25px" }}>
    <RiRoadMapLine />
  </IconContext.Provider>
)
const Growicon = () => (
  <IconContext.Provider value={{ color: "#E50012", size: "25px" }}>
    <RiPlantLine />
  </IconContext.Provider>
)
const Hearticon = () => (
  <IconContext.Provider value={{ color: "#E50012", size: "25px" }}>
    <RiServiceLine />
  </IconContext.Provider>
)
const Recruiticon = () => (
  <IconContext.Provider value={{ color: "#E50012", size: "25px" }}>
    <RiUserSearchLine />
  </IconContext.Provider>
)
const Sdgsicon = () => (
  <IconContext.Provider value={{ color: "#E50012", size: "25px" }}>
    <RiChatSmile3Line />
  </IconContext.Provider>
)

const StyledDiv = styled.div`
  background: transparent;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  .cards {
    background: ${({ theme }) => theme.colors.primary.gray};
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 20px;
    width: 200px;
    height: 200px;
    position: relative;
    margin: 0 15px 20px;
    border: 2px solid white;
    transition: 0.6s;
    &:hover {
      border: 2px solid ${({ theme }) => theme.colors.primary.red};
    }
    h4 {
      margin: 15px 0 10px;
      font-weight: bold;
    }
    p {
      font-size: 0.8em;
    }
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  ${() =>
    media.tablet(css`
      .cards {
        width: 100%;
        height: auto;
        margin: 0 0 20px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        svg {
          margin-right: 0.62em;
        }
        h4 {
          margin: 0;
        }
        p {
          margin-top: 0.32em;
          width: 100%;
        }
      }
    `)}
  ${() =>
    media.sp(css`
      justify-content: space-between;
      .cards {
        width: 48%;
        height: 150px;
        flex-flow: column nowrap;
        align-items: center;
        padding: 0 0.62em;
        justify-content: center;
      }
    `)}
`

const BizInfoCard = () => {
  return (
    <StyledDiv>
      <div className="cards">
        <Office />
        <h4>グループ構成</h4>
        <p>フランソア・グループの企業情報一覧です。</p>
        <Link to="/company-profiles/"></Link>
      </div>

      <div className="cards">
        <Mapicon />
        <h4>拠点一覧</h4>
        <p>フランソア・グループの事業所・工場等の拠点一覧です。</p>
        <Link to="/company-locations/"></Link>
      </div>

      <div className="cards">
        <Growicon />
        <h4>企業理念・沿革</h4>
        <p>これまでも、これからも。健康と美味しさのために。</p>
        <Link to="/company-vision/"></Link>
      </div>

      <div className="cards">
        <Hearticon />
        <h4>活動・取り組み</h4>
        <p>
          地域社会やお客様の安全・安心に関わることに使命と責任を持ち、取り組んでいます。
        </p>
        <Link to="/company-activities/"></Link>
      </div>

      <div className="cards">
        <Recruiticon />
        <h4>採用情報</h4>
        <p>フランソア・グループの採用情報です。</p>
        <Link to="/recruitment/"></Link>
      </div>

      <div className="cards">
        <Sdgsicon />
        <h4>SDGs</h4>
        <p>持続可能な未来を築くために。（特設サイト）</p>
        <a
          href="https://sdgs.francois-group.jp"
          target="_blank"
          rel="noopener noreferrer"
        ></a>
      </div>
    </StyledDiv>
  )
}

export default BizInfoCard
